import { MutableRefObject, RefObject } from 'react';
import { captureException } from '../tracking/sentry/sentry';
import { sendEvent, EVENT_NAMES } from './analytics';

export const sendViewVideoEvent = ({ videoId }: { videoId: number | undefined }) => {
  if (!videoId) {
    captureException(new Error('Video showed but is not defined, cannot send view analytics'));
    return;
  }
  sendEvent({
    name: EVENT_NAMES.viewVideo,
    properties: {
      'video id': videoId,
      location: window.location.pathname,
    },
  });
};

export const sendViewVideoCompletedEvent = ({
  videoId,
  videoRef,
}: {
  videoId: number;
  videoRef: RefObject<HTMLVideoElement> | MutableRefObject<HTMLVideoElement | undefined>;
}) => {
  if (!videoRef.current) {
    captureException(
      new Error('Video closed but ref is not defined, cannot send completion analytics'),
      {
        extra: {
          id: videoId,
        },
      },
    );
    return;
  }
  const { currentTime, duration } = videoRef.current;
  sendEvent({
    name: EVENT_NAMES.viewVideoCompleted,
    properties: {
      'video id': videoId,
      location: window.location.pathname,
      'total video duration in seconds': Math.round(duration),
      'watched video duration in seconds': Math.round(currentTime),
      'watched video percentage': Math.round((10 * currentTime) / duration) / 10,
    },
  });
};
