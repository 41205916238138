import config from '../config';
import { captureException } from '../tracking/sentry/sentry';
const API_HOST = config.CDN_API_URL;

/**
 * TODO in galaxy api, fix types
 */

export type Video = {
  createdAt: string;
  id: number;
  impacterId: number | null;
  key: string;
  metaData: MetaData;
  resources: Resource[];
  videoId: number;
  videoKey: string;
};
export type MetaData = {
  durationInMs: number;
  hasAudioTrack: boolean;
  orientation: string;
  originalHeight: number;
  originalWidth: number;
  posterSrc?: string;
  posters?: {
    firstFrame?: string;
    main?: string;
    animation?: string;
  };
  ratio: number;
  status: string;
};
export type Resource = {
  mimeType: string;
  src: string;
  orientation?: string;
};

export const getVideoById = async (videoId?: string | number): Promise<Video | undefined> => {
  // paths['/v2/videos/{id}']['get']['responses']
  try {
    if (videoId === undefined || videoId === null) {
      return;
    }
    const video = await fetch(`${API_HOST}content/v2/videos/${videoId}`);
    if (!video.ok) {
      captureException(new Error(`Failed getting video ${videoId}`));
      return;
    }
    return await video.json();
  } catch (error) {
    captureException(error);
  }
};
