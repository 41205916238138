import { useContext } from 'react';
import { getVideoById, Video } from '../../utils/api/videoApi';
import { colors } from '../../utils/styleguide';
import HeadlinerLayout from '../component-library/layout/HeadlinerLayout';
import Typography from '../component-library/text/Typography';
import { TemplatingContext } from './contexts/TemplatingContext';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText/RichText';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';
import sectionToCompatibleServerSideProps from './util/sectionToCompatibleServerSideProps';

type ServerSideProps = {
  portraitVideo?: Video;
  landscapeVideo?: Video;
  portraitBackgroundVideo?: Video;
  landscapeBackgroundVideo?: Video;
};

const HeadlinerSection: PageSectionComponent<ServerSideProps> = ({
  section,
  serverSideProps: {
    portraitVideo,
    landscapeVideo,
    portraitBackgroundVideo,
    landscapeBackgroundVideo,
  },
}) => {
  const { fields: { image: deprecatedImage } = {} } = section;
  const {
    id,
    title,
    description,
    button,
    backgroundColor,
    backgroundLinear,
    backgroundImage,
    backgroundOverlay,
    variant,
    overline,
  } = sectionToCompatibleProps({ section });

  const textColor = variant === 'light' ? colors.black : colors.white;

  const { templateReplacer } = useContext(TemplatingContext);
  let href = button?.href;
  if (href) {
    href = templateReplacer(href);
  }

  return (
    <HeadlinerLayout
      id={id}
      title={<RichText document={title} textColor={textColor} />}
      description={description && <RichText document={description} textColor={textColor} />}
      image={backgroundImage || deprecatedImage}
      CTAText={button?.label}
      CTAPrompt={button?.prompt}
      CTAColor={button?.colorContext}
      buttonSmall={button?.size === 'small'}
      portraitVideo={portraitVideo}
      landscapeVideo={landscapeVideo}
      portraitBackgroundVideo={portraitBackgroundVideo}
      landscapeBackgroundVideo={landscapeBackgroundVideo}
      href={href}
      backgroundColor={backgroundColor}
      backgroundLinear={backgroundLinear}
      backgroundOverlay={backgroundOverlay}
      preheader={
        overline && (
          <Typography color={textColor} variant="overline">
            {overline}
          </Typography>
        )
      }
    />
  );
};

HeadlinerSection.getCustomServerSideProps = async ({ section }) => {
  const {
    backgroundVideoIdLandscape,
    backgroundVideoIdPortrait,
    videoIdLandscape,
    videoIdPortrait,
  } = sectionToCompatibleServerSideProps({ section });
  const [portraitVideo, landscapeVideo, portraitBackgroundVideo, landscapeBackgroundVideo] =
    await Promise.all([
      getVideoById(videoIdPortrait),
      getVideoById(videoIdLandscape),
      getVideoById(backgroundVideoIdPortrait),
      getVideoById(backgroundVideoIdLandscape),
    ]);
  return { portraitVideo, landscapeVideo, portraitBackgroundVideo, landscapeBackgroundVideo };
};

export default HeadlinerSection;
