import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import { Video } from '../../utils/api/videoApi';
import { getDefaultAspectRatio, getStreamingSrc } from '../../utils/helpers/video';
import { useDeviceVideo } from '../../utils/hooks/useDeviceVideo';
import { colors, spacing } from '../../utils/styleguide';
import PrimaryButton, { ButtonColor, ButtonVariant } from './buttons/PrimaryButton';
import OverlayVideoPlayer from './OverlayVideoPlayer';
import { PlayFilledAlt } from '@carbon/icons-react';

const Label = styled.div`
  display: flex;
  align-items: center;
`;

type VideoPlayButtonProps = {
  buttonLabel: ReactNode;
  buttonColor?: ButtonColor;
  buttonVariant?: ButtonVariant;
  landscapeVideo?: Video;
  portraitVideo?: Video;
};

const VideoPlayButton = ({
  buttonLabel,
  buttonColor,
  buttonVariant,
  landscapeVideo,
  portraitVideo,
}: VideoPlayButtonProps) => {
  const [showVideo, setShowVideo] = useState(false);
  const video = useDeviceVideo({ landscapeVideo, portraitVideo });
  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
  const arrowColor = !buttonColor || buttonColor === 'purple' ? colors.white : colors.black;

  return (
    <>
      <PrimaryButton
        label={
          <Label>
            <span
              css={css`
                margin-right: ${spacing[2]}px;
              `}
            >
              {buttonLabel || 'Watch our video'}
            </span>
            <PlayFilledAlt size={16} color={arrowColor} />
          </Label>
        }
        color={buttonColor}
        variant={buttonVariant || 'solid'}
        size={'large'}
        onClick={() => setShowVideo(true)}
      />
      {showVideo && video && (
        <OverlayVideoPlayer
          src={getStreamingSrc({ video })}
          aspectRatio={getDefaultAspectRatio(video)}
          onClose={() => setShowVideo(false)}
          videoId={video?.id}
          videoRef={videoRef}
        />
      )}
    </>
  );
};

export default VideoPlayButton;
