import { TypePageSection } from '../../../../@types/generated';
import orientedMediaToCompatibleProps from './orientedMediaToCompatibleProps';

export default function sectionToCompatibleServerSideProps({
  section,
}: {
  section: TypePageSection;
}) {
  const {
    videoIdLandscape: backgroundVideoIdLandscape,
    videoIdPortrait: backgroundVideoIdPortrait,
  } = orientedMediaToCompatibleProps({
    orientedMedia: section.fields.backgroundEntry?.fields.orientedMedia,
  });

  const { videoIdLandscape, videoIdPortrait } = orientedMediaToCompatibleProps({
    orientedMedia: section.fields.orientedMedia,
  });

  return {
    backgroundVideoIdLandscape,
    backgroundVideoIdPortrait,
    videoIdLandscape,
    videoIdPortrait,
  };
}
